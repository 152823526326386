import React from 'react';
import {Button} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {sendToPooling, sendToPoolingProgressSelector} from "../../ducks/gridList";
import {useDispatch, useSelector} from "react-redux";

const PoolingButtons = ({value, id}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const sendToPoolingProgress = useSelector(state => sendToPoolingProgressSelector(state));
    const send = () => {
        dispatch(sendToPooling({id}));
    }
    const open = () => {
        window.open(`${value}`, '_blank');
    }

    return (
        <div className='cell-buttons'>
            {
                value
                    ? <Button
                        onClick={send}
                        disabled={sendToPoolingProgress}
                        loading={sendToPoolingProgress}
                    >
                        {t('sendToPoolingButton')}
                    </Button>
                    : <Button
                        onClick={open}
                    >
                        {t('openInPoolingButton')}
                    </Button>
            }
        </div>
    );
};

export default PoolingButtons;